import React, {useState, useEffect} from 'react';
import { removeUserSession, getToken, getOwner, getProductCode, getLotNo } from './Utils/Common';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from './Copyright';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import NodeDetailTable from './NodeDetailTable';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';

import configuration from '../assets/Config.json';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  textField: {
    width: '35ch',
    margin: theme.spacing(1),
  },  
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  bottom: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    right: 50,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,    
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedSubtitle: {
    textTransform: 'uppercase',
    textAlign: 'left',
    fontWeight: 'bold',
  },
}));

export default function SearchResult(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [productdata, setProductData] = useState([]);
  const [chaindata, setChainData] = useState();
  const [nodedetail, setNodeDetail] = useState();
  const [nodedocuments, setNodeDocuments] = useState();
  const [nodesources, setNodeSources] = useState();
  const [nodedetailavailable, setNodeDetailAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  useEffect(() => {

    const token = getToken();
    if (!token) {
      return;
    }

    const owner = getOwner();
    const product_code = getProductCode();
    const lot_no = getLotNo();
    const trace_url = configuration.url.base_backend_url + configuration.url.tracing_url;
    var children = [];

    setLoading(true);
    axios.get(`${trace_url}?token=${token}&owner=${owner}&product_code=${product_code}&lot_no=${lot_no}`).then(response => {
      var tmp = response.data;
      setChainData(tmp);
      var keys = Object.keys(tmp);
      var i = 0;
      for(i=0; i<keys.length; i++) {
        var element = {'id': keys[i], 'name': keys[i]};
        children.push(element);
      }

      setProductData(children);
      setLoading(false);
      setExpanded(true);
    }).catch(error => {
    });


  }, []);

  
  function openDetail(value)
  {
    setSelectedIndex(value);
    if(value === "root") {
      setNodeDetail([]);
      setNodeDocuments([]);
      setNodeSources([]);
    } else {
      var el = [];
      var docs = [];
      var sources = [];

      var itemId = 1;
      chaindata[value].forEach(element => {
        var tmp1 = [{
          key: 'productCode',
          value: element.productCode,
          label: 'Product code'
        },
        {
          key: 'owner',
          value: element.owner,
          label: 'Owner'
        },
        {
          key: 'lotNo',
          value: element.lotNo,
          label: 'Lot number'
        },
        {
          key: 'unitMeasure',
          value: element.unitMeasure,
          label: 'Unit measure'
        },
        {
          key: 'quantity',
          value: element.quantity,
          label: 'Quantity'
        },
        {
          key: 'taricCode',
          value: element.taricCode,
          label: 'TARIC code'
        },
        {
          key: 'country',
          value: element.country,
          label: 'Country'
        },
        {
          key: 'date',
          value: element.date,
          label: 'Date'
        },
        {
          key: 'currency',
          value: element.currency,
          label: 'Currency'
        },
        {
          key: 'unitPrice',
          value: element.unitPrice,
          label: 'Unit price'
        }
        ];
        el.push({key: itemId, value: tmp1});
        docs.push({key: itemId, value: element.documents})
        sources.push({key: itemId, value: element.sources})
        itemId++;
      });
      
      setNodeDetail(el);
      setNodeDocuments(docs);
      setNodeSources(sources);
      setNodeDetailAvailable(true);      
      //console.log(docs);
    }
    
  }

  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    props.history.push('/login');
  }

  const handleHome = () => {
    props.history.push('/dashboard');
  }

  return (
    <React.Fragment>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" color="inherit" noWrap>
            Domina SRL
          </Typography>
          <div style={{ flex: 1 }} align="right">
            <Link variant="button" color="textPrimary" href="#" onClick={handleHome} className={classes.link}>
              Home
            </Link>
            <Link variant="button" color="textPrimary" href="#" onClick={handleLogout} className={classes.link}>
              Logout
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <div style={{justifyContent: 'center', marginBottom: 1, marginTop: 1}}>
        {
          loading ? 
          <LinearProgress /> : <LinearProgress variant="determinate" value={100}/> 
        }
        </div>
      
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <Typography variant="h5" align="center" color="textPrimary" component="p">
          Search results
        </Typography>
        
        <ExpansionPanel expanded={expanded}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <Typography variant="h6" color="textSecondary" >Select the product code to view related information</Typography>
          {/*
          {loading ? <CircularProgress variant="indeterminate" disableShrink className={classes.bottom} size={24} thickness={4}/> : null}
            */}
          </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            {/*{content}*/}
            <Grid container spacing={2}>
              <Grid item xs={3}>
            <List component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader className={classes.nestedSubtitle} component="div" id="nested-list-subheader">
                  Product codes
                </ListSubheader>
              }
              className={classes.root}>
                {productdata.map(product => (
                  <ListItem button key={product.id} selected={selectedIndex === product.id}>
                    <ListItemIcon>
                      <ClassOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={product.name} onClick={() => openDetail(product.id)}/>
                  </ListItem>
                ))}
              </List>
              </Grid>
              <Grid item xs={9}>
                
              {nodedetailavailable ? 
              <div>
                {nodedetail.map((row, index) => (
                  <NodeDetailTable key={row.key} {...props} nodedetail={row.value} nodedocuments={nodedocuments[index].value} nodesources={nodesources[index].value}/>
                ))}
              </div>
              : <p>Please, select a product code</p>
              }                  
              </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </Container>
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
          <Copyright />
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}