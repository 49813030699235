import React, {useEffect, useState} from 'react';
import { removeUserSession, setSearchInput, getToken } from './Utils/Common';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Copyright from './Copyright';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  textField: {
    width: '35ch',
    margin: theme.spacing(1),
  },  
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  bottom: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    right: 50,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedSubtitle: {
    textTransform: 'uppercase',
    textAlign: 'left',
    fontWeight: 'bold',
  },
}));

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default function Dashboard(props) {
  const classes = useStyles();
  const owner = useFormInput('');
  const product_code = useFormInput('');
  const lot_no = useFormInput('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

  }, []);



  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    props.history.push('/login');
  }

  const handleHome = () => {
    props.history.push("/dashboard");
  }

  const doSearch = () => {
    //var owner = "atlas_international_textiles_test2";
    //var product_code = "16_36_test2";
    //var lot_no = "LTF1_test2";
    if(owner.value === '' || product_code.value === '' || lot_no.value === '') {
      setError("Invalid data, please fill the form with some values.");
    } else {
      setSearchInput(owner.value, product_code.value, lot_no.value);
      props.history.push("/search-result");
    }
  }

  const doSearchExample = () => {
    var owner = "atlas_international_textiles_test2";
    var product_code = "16_36_test2";
    var lot_no = "LTF1_test2";
    
    setSearchInput(owner, product_code, lot_no);
    props.history.push("/search-result");
  }

  return (
    <React.Fragment>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" color="inherit" noWrap>
            Domina SRL
          </Typography>
          <div style={{ flex: 1 }} align="right">
            <Link variant="button" color="textPrimary" href="#" onClick={handleHome} className={classes.link}>
              Home
            </Link>
            <Link variant="button" color="textPrimary" href="#" onClick={handleLogout} className={classes.link}>
              Logout
            </Link>
          </div>
        </Toolbar>
      </AppBar>

      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography variant="h5" align="center" color="textPrimary" component="p">
          Fill the form with the required information
        </Typography>
        <form noValidate autoComplete="off">
          <div>
            <TextField required id="owner" name="owner" {...owner} className={classes.textField} label="Owner identifier" />
          </div>
          <div>
            <TextField required id="product_code" name="product_code" {...product_code} className={classes.textField} label="Product code"  />
          </div>
          <div>
            <TextField required id="lot_no" name="lot_no" {...lot_no} className={classes.textField} label="Lot number"  />
          </div>
          <div>
          <Button variant="contained" onClick={doSearch}>Search</Button> 
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={doSearchExample}>Try example</Button>
          </div>
        </form>
        {error && <div><br /><Alert severity="error">{error}</Alert></div>}
      </Container>
      
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
          <Copyright />
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}