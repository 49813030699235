// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('type');
}

// set the token and user from the session storage
export const setUserSession = (token, user, type) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('type', type);
}

// set owner, product_code, lot_no
export const setSearchInput = (owner, product_code, lot_no) => {
  sessionStorage.setItem('owner', owner);
  sessionStorage.setItem('product_code', product_code);
  sessionStorage.setItem('lot_no', lot_no);
}

// return owner
export const getOwner = () => {
  return sessionStorage.getItem('owner') || null;
}

// product_code
export const getProductCode = () => {
  return sessionStorage.getItem('product_code') || null;
}

// return lot_no
export const getLotNo = () => {
  return sessionStorage.getItem('lot_no') || null;
}