import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  textField: {
    width: '35ch',
    margin: theme.spacing(1),
  },  
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  bottom: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    right: 50,
  },
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },

  table: {
    minWidth: 650,
    tableLayout: 'fixed',
    //whiteSpace: 'nowrap',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  card: {
    padding: 20,
    marginBottom: 20,
    backgroundColor: theme.palette.grey[100],
  },
  divider: {
    marginTop: 20,
    marginBottom: 15,
  },
  cardSubtitle: {
    textTransform: 'uppercase',
    textAlign: 'left',
    fontWeight: 'bold',
  },

}));

const StyledTableCellProduct = withStyles((theme) => ({
    head: {
      backgroundColor: '#c94c4c',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
const StyledTableCellDocument = withStyles((theme) => ({
  head: {
    backgroundColor: '#88b04b',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCellSource = withStyles((theme) => ({
  head: {
    backgroundColor: '#000000',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function NodeDetailTable(props) {
  const classes = useStyles();
  const details = props.nodedetail;
  const docs = props.nodedocuments;
  const sources = props.nodesources;
  var organizeddocs = [];
  var organizedsources = [];
  
  var itemId = 1;
  docs.forEach(element => {
    //console.log("type:" + element.type);
    //console.log("producer:" + element.producer);
    //console.log("------");
    var tmp1 = [
      {
        key: 'producer',
        value: element.producer,
        label: 'Producer'
      },
      {
        key: 'type',
        value: element.type,
        label: 'Type'
      },
      {
        key: 'number',
        value: element.number,
        label: 'Number'
      },
      {
        key: 'date',
        value: element.date,
        label: 'Date'
      },
      {
        key: 'hash',
        value: element.hash,
        label: 'Hash'
      },
      {
        key: 'hash_algorithm',
        value: element.hash_algorithm,
        label: 'Hash algorithm'
      },
      {
        key: 'file_location',
        value: element.fileLocation,
        label: 'File location'
      },
    ];
    organizeddocs.push({key: itemId, value: tmp1});
    itemId++;
  });
  //console.log(organizeddocs);
  
  itemId = 1;
  sources.forEach(element => {
    //console.log("type:" + element.type);
    //console.log("producer:" + element.producer);
    //console.log("------");
    var tmp1 = [
      {
        key: 'owner',
        value: element.owner,
        label: 'Owner'
      },
      {
        key: 'productCode',
        value: element.productCode,
        label: 'Product code'
      },
      {
        key: 'lotNo',
        value: element.lotNo,
        label: 'Lot number'
      },
      {
        key: 'quantity',
        value: element.quantity,
        label: 'Quantity'
      }
    ];
    organizedsources.push({key: itemId, value: tmp1});
    itemId++;
  });

  return (
    <React.Fragment>
    <Card className={classes.card}>
      <Typography variant="body1" className={classes.cardSubtitle}>Product item</Typography>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCellProduct><strong>Attribute</strong></StyledTableCellProduct>
            <StyledTableCellProduct><strong>Content</strong></StyledTableCellProduct>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((row) => (
            <TableRow key={row.key}>
              <StyledTableCellProduct component="th" scope="row">
                <strong><i>{row.label}</i></strong>
              </StyledTableCellProduct>
              <StyledTableCellProduct>{row.value}</StyledTableCellProduct>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

      <Divider className={classes.divider}/>
      <Typography variant="body1" className={classes.cardSubtitle}>Related documents (found {organizeddocs.length} docs)</Typography>
        {
          organizeddocs.length > 0 ?
            organizeddocs.map((row) => (
              <div key={row.key}>
                <p>Document {row.key}</p>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCellDocument><strong>Attribute</strong></StyledTableCellDocument>
                    <StyledTableCellDocument><strong>Content</strong></StyledTableCellDocument>
                  </TableRow>
                </TableHead>
                <TableBody>
                {row.value.map((item) => (
                  <TableRow key={item.key}>
                    <StyledTableCellDocument component="th" scope="row">
                    <strong><i>{item.label}</i></strong>
                    </StyledTableCellDocument>
                    {
                      item.key === 'file_location' ? <StyledTableCellDocument><Link href={item.value}>{item.value}</Link></StyledTableCellDocument> : <StyledTableCellDocument>{item.value}</StyledTableCellDocument>
                    }
                  </TableRow>
                  ))
                }
                </TableBody>
                </Table>
              </TableContainer>
              <br />
              </div>
            ))
          : <p>No document available for this product item.</p>
        }
    
    <Divider className={classes.divider}/>
      <Typography variant="body1" className={classes.cardSubtitle}>Related sources (found {organizedsources.length} sources)</Typography>
        {
          organizedsources.length > 0 ?
            organizedsources.map((row) => (
              <div key={row.key}>
                <p>Source {row.key}</p>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCellSource><strong>Attribute</strong></StyledTableCellSource>
                    <StyledTableCellSource><strong>Content</strong></StyledTableCellSource>
                  </TableRow>
                </TableHead>
                <TableBody>
                {row.value.map((item) => (
                  <TableRow key={item.key}>
                    <StyledTableCellDocument component="th" scope="row">
                    <strong><i>{item.label}</i></strong>
                    </StyledTableCellDocument>
                    {
                      item.key === 'file_location' ? <StyledTableCellSource><Link href={item.value}>{item.value}</Link></StyledTableCellSource> : <StyledTableCellSource>{item.value}</StyledTableCellSource>
                    }
                  </TableRow>
                  ))
                }
                </TableBody>
                </Table>
              </TableContainer>
              <br />
              </div>
            ))
          : <p>No source available for this product item.</p>
        }
    </Card>
    
    </React.Fragment>
  );
          
}
