import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from './Copyright';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundImage: `url(${"background.jpg"})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  main: {
    marginTop: theme.spacing(28),
    marginBottom: theme.spacing(2),
    //marginLeft: 0,
    color: '#ffffff',
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          Tracing application
        </Typography>
        <Divider />
        <br />
        <Typography variant="body1">Please, <Link color="secondary" href="./dashboard">login</Link> to access the platform.</Typography>
      
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}

export default Home;